.event-container {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 10px;
}

.delete-icon {
  opacity: 0;
  transition: opacity 0.3s;
  margin-left: 5px;
  z-index: 1;
  cursor: pointer;
}

.event-container:hover .delete-icon {
  opacity: 1;
}
